import { Layout, Spin, theme } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { ROUTE_PATH } from '../../routes/route.constant'
import CommonServices from '../../services/CommonServices'
import {
  changeEnumType,
  getListCost,
  getListEmployee,
  getListProject,
  getListVendor,
} from '../../store/commonSlice'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
const { Content, Sider } = Layout

const MainLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const dispatch = useDispatch()
  const location = useLocation()

  const [loading, setLoading] = useState(false)

  const getEnumType = async () => {
    const res = await CommonServices.getEnumType()

    if (!!res.status) {
      dispatch(changeEnumType(res.data))
    }
  }

  const getCost = async () => {
    const res = await CommonServices.getDropDownMenu('Cost')

    if (res.status) {
      const listCost = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      dispatch(getListCost(listCost))
    }
  }

  const getVendor = async () => {
    const res = await CommonServices.getDropDownMenu('Vendor')

    if (res.status) {
      const listVendor = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      dispatch(getListVendor(listVendor))
    }
  }

  const getProject = async () => {
    const res = await CommonServices.getDropDownMenu('Project')

    if (res.status) {
      const listProject = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      dispatch(getListProject(listProject))
    }
  }

  const getEmployee = async () => {
    const res = await CommonServices.getDropDownMenu('Employee')

    if (res.status) {
      const listEmployee = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      dispatch(getListEmployee(listEmployee))
    }
  }

  const getSystemKey = async () => {
    setLoading(true)
    await Promise.all([
      getEnumType(),
      getProject(),
      getCost(),
      getEmployee(),
      getVendor(),
    ])
    setLoading(false)
  }

  useEffect(() => {
    getSystemKey()
  }, [])

  return (
    <Layout>
      <Sider
        width={250}
        style={{
          background: colorBgContainer,
          borderRight: '1px solid rgba(0, 0, 0, 0.10)',
        }}
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Navbar />
        <Content style={{ padding: '20px' }}>
          <div
            className={`h-full rounded-xl p-5 ${
              location.pathname === ROUTE_PATH.EMPLOYEE_DETAIL ? '' : 'bg-white'
            }`}
          >
            <Spin spinning={loading}>
              <Outlet />
            </Spin>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
