import { useLocation, useNavigate } from 'react-router-dom'
import { NavbarWrapper } from './styled'
import { ROUTE_PATH } from '../../routes/route.constant'
import AuthServices from '../../services/AuthServices'

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const titlePage = () => {
    switch (location.pathname) {
      case '/':
      case ROUTE_PATH.DASHBOARD:
        return (
          <div className="text-header">
            <div>Dashboard</div>
          </div>
        )
      case ROUTE_PATH.FIXED_ASSET:
        return <div className="text-header"> Quản lý tài sản cố định</div>
      case ROUTE_PATH.OFFICE_EXPENSES:
        return <div className="text-header"> Chi tiêu văn phòng khác</div>
      case ROUTE_PATH.VENDOR_MANAGEMENT:
        return <div className="text-header"> Quản lý vendor</div>
      case ROUTE_PATH.EMPLOYEE:
        return <div className="text-header">Quản lý nhân viên</div>
      case ROUTE_PATH.EMPLOYEE_DETAIL:
        return <div className="text-header">Thông tin chi tiết</div>
      case ROUTE_PATH.RESOURCE_SITUATION:
        return <div className="text-header">Tình trạng sử dụng nhân lực</div>
      case ROUTE_PATH.CONTRACT:
        return <div className="text-header">Hợp đồng lao động</div>
      case ROUTE_PATH.CUSTOMER:
        return <div className="text-header"> Quản lý khách hàng</div>
      case ROUTE_PATH.PROJECT_CONTRACT:
        return <div className="text-header"> Hợp đồng</div>
      case ROUTE_PATH.EVENTS:
        return <div className="text-header"> Sự kiện</div>
      case ROUTE_PATH.PROJECT_LIST:
        return <div className="text-header"> Danh sách dự án thực thi</div>
      case ROUTE_PATH.STAFFING:
        return <div className="text-header"> Bố trí nhân sự hàng tháng</div>
      case ROUTE_PATH.REVENUE_COST:
        return <div className="text-header"> Doanh thu/chi phí</div>
      case ROUTE_PATH.OUTPUT:
        return (
          <div className="text-header">
            <div>Output</div>
          </div>
        )
      default:
        break
    }
  }

  const handleLogout = () => {
    AuthServices.logout().then((res) => {
      if (res.status) {
        localStorage.clear()
        navigate(ROUTE_PATH.LOGIN)
      }
    })
  }

  return (
    <NavbarWrapper>
      <div className="admin">
        <div style={{ cursor: 'pointer' }}>
          <img
            src={process.env.PUBLIC_URL + '/images/admin.png'}
            width={'40px'}
            alt=""
          />
        </div>
        <div style={{ cursor: 'pointer' }} onClick={handleLogout}>
          <img
            src={process.env.PUBLIC_URL + '/images/logout.png'}
            width={'24px'}
            alt=""
          />
        </div>
      </div>
      {titlePage()}
    </NavbarWrapper>
  )
}

export default Navbar
