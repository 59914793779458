import React, { Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { ROUTE_PATH } from './route.constant'

import AppLayout from '../layouts/AppLayout'
import AuthLayout from '../layouts/AuthLayout'
import MainLayout from '../layouts/MainLayout'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import Output from '../pages/Output'

const FixedAsset = React.lazy(() => import('../pages/BackOffice/FixedAsset'))
const OfficeExpenses = React.lazy(
  () => import('../pages/BackOffice/OfficeExpenses'),
)
const Vendor = React.lazy(() => import('../pages/BackOffice/Vendor'))

const Employee = React.lazy(() => import('../pages/HumanResources/Employee'))
const EmployeeDetail = React.lazy(() => import('../pages/EmployeeDetail'))
const ResourceSituation = React.lazy(
  () => import('../pages/HumanResources/ResourceSituation'),
)
const Contract = React.lazy(() => import('../pages/HumanResources/Contract'))
const ContractManagement = React.lazy(
  () => import('../pages/ContractManagement'),
)

const Customer = React.lazy(() => import('../pages/SalesMarketing/Customer'))
const ProjectContract = React.lazy(
  () => import('../pages/SalesMarketing/ProjectContract'),
)
const Events = React.lazy(() => import('../pages/SalesMarketing/Events'))

const ProjectList = React.lazy(() => import('../pages/Project/ProjectList'))
const Staffing = React.lazy(() => import('../pages/Project/Staffing'))
const RevenueCost = React.lazy(() => import('../pages/Project/RevenueCost'))
const HistoryTransaction = React.lazy(
  () => import('../pages/HistoryTransaction'),
)

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTE_PATH.LOGIN,
            element: (
              <Suspense fallback={null}>
                <Login />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTE_PATH.DASHBOARD,
            element: (
              <Suspense fallback={null}>
                <Dashboard />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.FIXED_ASSET,
            element: (
              <Suspense fallback={null}>
                <FixedAsset />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.OFFICE_EXPENSES,
            element: (
              <Suspense fallback={null}>
                <OfficeExpenses />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.VENDOR_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <Vendor />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.EMPLOYEE,
            element: (
              <Suspense fallback={null}>
                <Employee />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.EMPLOYEE_DETAIL,
            element: (
              <Suspense fallback={null}>
                <EmployeeDetail />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.RESOURCE_SITUATION,
            element: (
              <Suspense fallback={null}>
                <ResourceSituation />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CONTRACT,
            element: (
              <Suspense fallback={null}>
                <Contract />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CONTRACT_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <ContractManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CUSTOMER,
            element: (
              <Suspense fallback={null}>
                <Customer />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PROJECT_CONTRACT,
            element: (
              <Suspense fallback={null}>
                <ProjectContract />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.EVENTS,
            element: (
              <Suspense fallback={null}>
                <Events />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PROJECT_LIST,
            element: (
              <Suspense fallback={null}>
                <ProjectList />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.STAFFING,
            element: (
              <Suspense fallback={null}>
                <Staffing />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.REVENUE_COST,
            element: (
              <Suspense fallback={null}>
                <RevenueCost />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.OUTPUT,
            element: (
              <Suspense fallback={null}>
                <Output />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.HISTORY_TRANSACTION,
            element: (
              <Suspense fallback={null}>
                <HistoryTransaction />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
])
