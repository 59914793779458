/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { AuthenticationWrapper } from './styled'
import LoginForm from './LoginForm'

const Authentication = () => {
  return (
    <AuthenticationWrapper
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-login.jpg`,
      }}
    >
      <div className="logo-app">
        <img
          src={process.env.PUBLIC_URL + '/images/logo-1bitlab-login.png'}
          width={330}
          alt=""
        />
      </div>

      <LoginForm />
    </AuthenticationWrapper>
  )
}

export default Authentication
