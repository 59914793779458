import React from 'react'
import { ButtonStyled } from './styled'
import { PropTypes } from 'prop-types'

const Button = React.memo((props) => {
  const { onClick } = props
  return <ButtonStyled onClick={onClick} {...props}></ButtonStyled>
})

Button.propTypes = {
  onClick: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null, undefined]),
  ]),
}

Button.defaultProps = {
  onClick: null,
}

export default Button
