import { Button } from 'antd'
import styled from 'styled-components'

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled, bg_color }) =>
    disabled ? '#CECECE' : bg_color ? bg_color : '#fff'};
  font-size: ${({ size }) => (size ? size : 14)}px;
  font-style: normal;
  font-weight: 400;
  /* width: 120px; */
  min-width: 80px;
  border-radius: 10px;
  color: ${({ disabled, color }) =>
    disabled ? '#000' : color ? color : '#000'};

  /* box-shadow: ${({ shadow, color }) =>
    color === 'gray'
      ? `inset 6px 5px 13px #e8e8e8`
      : shadow
        ? `inset 6px 5px 13px ${shadow}`
        : 'none'}; */
`
