import { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../routes/route.constant'
import { getAccessToken } from '../../helper/utils'

const AppLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isLogin = getAccessToken()

  useEffect(() => {
    if (!isLogin) return navigate(ROUTE_PATH.LOGIN)
    if (location.pathname === '/') navigate(ROUTE_PATH.DASHBOARD)
  }, [])

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  )
}

export default AppLayout
