import { Spin } from 'antd'
import React from 'react'

const Dashboard = () => {
  return (
    <div>
      <Spin spinning={false}>Thống kê ở đây</Spin>
    </div>
  )
}

export default Dashboard
