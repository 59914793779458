// commons
export const apiEnumType = '/api/v1/commons/enum-types/'
export const apiDropDownMenu = '/api/v1/commons/drop-down-menu'

// Auth
export const apiLogin = '/api/v1/auth/login'
export const apiLogout = '/api/v1/auth/logout'
export const apiRefreshToken = '/api/v1/auth/refresh'

// Files
export const apiDownloadFile = '/api/v1/files/download-private'
export const apiUploadFile = '/api/v1/files/upload'

// User
export const apiGetInfo = '/api/v1/users/me'

// Company
export const apiCompanyProp = '/api/v1/company-properties/'
export const apiProportion = '/api/v1/company-properties/proportion/'

// Vendor
export const apiGetVendors = '/api/v1/vendors'
export const apiCreateVendor = '/api/v1/vendors/'

// Human Resource
export const apiGetEmployee = '/api/v1/human-resources/employees/'
export const apiProjectEffort = '/api/v1/human-resources/project-efforts/'
export const apiAnalysisEmployee = '/api/v1/human-resources/employees/analysis/'
export const apiLaborContracts = '/api/v1/human-resources/labor-contracts/'

// Project
export const apiProject = '/api/v1/projects/'
