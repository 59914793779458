export const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  FIXED_ASSET: '/fixed-asset',
  OFFICE_EXPENSES: '/office-expenses',
  VENDOR_MANAGEMENT: '/vendor-management',
  HISTORY_TRANSACTION: '/history-transaction',
  EMPLOYEE: '/employee',
  RESOURCE_SITUATION: '/resource-situation',
  CONTRACT: '/contract',
  CUSTOMER: '/customer',
  PROJECT_CONTRACT: '/project-contract',
  EVENTS: '/events',
  PROJECT_LIST: '/project-list',
  STAFFING: '/staffing',
  REVENUE_COST: '/revenue-cost',
  OUTPUT: '/output',
  EMPLOYEE_DETAIL: '/employee-detail',
  CONTRACT_MANAGEMENT: '/contract-management',
}
