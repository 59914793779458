/* eslint-disable no-unused-vars */
import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SidebarWrapper } from './styled'
import { ROUTE_PATH } from '../../routes/route.constant'

const rootSubmenuKeys = [
  {
    tabKey: 'sub0',
    tabPath: ROUTE_PATH.DASHBOARD,
  },
  {
    tabKey: 'sub1_1',
    tabPath: ROUTE_PATH.FIXED_ASSET,
  },
  {
    tabKey: 'sub1_2',
    tabPath: ROUTE_PATH.OFFICE_EXPENSES,
  },
  {
    tabKey: 'sub1_3',
    tabPath: ROUTE_PATH.VENDOR_MANAGEMENT,
  },
  {
    tabKey: 'sub1_3',
    tabPath: ROUTE_PATH.HISTORY_TRANSACTION,
  },

  {
    tabKey: 'sub2_1',
    tabPath: ROUTE_PATH.EMPLOYEE,
  },
  {
    tabKey: 'sub2_1',
    tabPath: ROUTE_PATH.EMPLOYEE_DETAIL,
  },
  {
    tabKey: 'sub2_2',
    tabPath: ROUTE_PATH.RESOURCE_SITUATION,
  },
  {
    tabKey: 'sub2_3',
    tabPath: ROUTE_PATH.CONTRACT,
  },
  {
    tabKey: 'sub3_1',
    tabPath: ROUTE_PATH.CUSTOMER,
  },
  {
    tabKey: 'sub3_2',
    tabPath: ROUTE_PATH.PROJECT_CONTRACT,
  },
  {
    tabKey: 'sub3_3',
    tabPath: ROUTE_PATH.EVENTS,
  },
  {
    tabKey: 'sub4_1',
    tabPath: ROUTE_PATH.PROJECT_LIST,
  },
  {
    tabKey: 'sub4_2',
    tabPath: ROUTE_PATH.STAFFING,
  },
  {
    tabKey: 'sub4_3',
    tabPath: ROUTE_PATH.REVENUE_COST,
  },
  {
    tabKey: 'sub5',
    tabPath: ROUTE_PATH.OUTPUT,
  },
]

const Sidebar = () => {
  const location = useLocation()
  const [keySelected, setKeySelected] = useState('')
  const [openKeys, setOpenKeys] = useState([])
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }

  const items = [
    getItem(<Link to={ROUTE_PATH.DASHBOARD}>Dashboard</Link>, 'sub0'),
    getItem(
      <Link
        to="#"
        className={
          [
            ROUTE_PATH.FIXED_ASSET,
            ROUTE_PATH.OFFICE_EXPENSES,
            ROUTE_PATH.VENDOR_MANAGEMENT,
            ROUTE_PATH.HISTORY_TRANSACTION,
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        Backoffice
      </Link>,
      'sub1',
      null,
      [
        getItem(
          <Link to={ROUTE_PATH.FIXED_ASSET}>Quản lý tài sản cố định</Link>,
          'sub1_1',
        ),
        getItem(
          <Link to={ROUTE_PATH.OFFICE_EXPENSES}>Chi tiêu văn phòng khác</Link>,
          'sub1_2',
        ),
        getItem(
          <Link to={ROUTE_PATH.VENDOR_MANAGEMENT}>Quản lý vendor</Link>,
          'sub1_3',
        ),
      ],
    ),
    getItem(
      <Link
        to="#"
        className={
          [
            ROUTE_PATH.EMPLOYEE,
            ROUTE_PATH.EMPLOYEE_DETAIL,
            ROUTE_PATH.RESOURCE_SITUATION,
            ROUTE_PATH.CONTRACT,
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        Human Resources
      </Link>,
      'sub2',
      null,
      [
        getItem(
          <Link to={ROUTE_PATH.EMPLOYEE}>Quản lý nhân viên</Link>,
          'sub2_1',
        ),
        getItem(
          <Link to={ROUTE_PATH.RESOURCE_SITUATION}>
            Tình trạng sử dụng nhân lực
          </Link>,
          'sub2_2',
        ),
        getItem(
          <Link to={ROUTE_PATH.CONTRACT}>Hợp đồng lao động</Link>,
          'sub2_3',
        ),
      ],
    ),
    getItem(
      <Link
        to="#"
        className={
          [
            ROUTE_PATH.CUSTOMER,
            ROUTE_PATH.PROJECT_CONTRACT,
            ROUTE_PATH.EVENTS,
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        Sales & Marketing
      </Link>,
      'sub3',
      null,
      [
        getItem(
          <Link to={ROUTE_PATH.CUSTOMER}>Quản lý khách hàng</Link>,
          'sub3_1',
        ),
        getItem(
          <Link to={ROUTE_PATH.PROJECT_CONTRACT}>Hợp đồng</Link>,
          'sub3_2',
        ),
        getItem(<Link to={ROUTE_PATH.EVENTS}>Sự kiện</Link>, 'sub3_3'),
      ],
    ),
    getItem(
      <Link
        to="#"
        className={
          [
            ROUTE_PATH.PROJECT_LIST,
            ROUTE_PATH.STAFFING,
            ROUTE_PATH.REVENUE_COST,
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        Project Management
      </Link>,
      'sub4',
      null,
      [
        getItem(
          <Link to={ROUTE_PATH.PROJECT_LIST}>Danh sách dự án thực thi</Link>,
          'sub4_1',
        ),
        getItem(
          <Link to={ROUTE_PATH.STAFFING}>Bố trí nhân sự hàng tháng</Link>,
          'sub4_2',
        ),
        getItem(
          <Link to={ROUTE_PATH.REVENUE_COST}>Doanh thu/chi phí</Link>,
          'sub4_3',
        ),
      ],
    ),
    getItem(<Link to={ROUTE_PATH.OUTPUT}>Output</Link>, 'sub5'),
  ]

  const updateOpenKeys = () => {
    switch (location.pathname) {
      case ROUTE_PATH.FIXED_ASSET:
      case ROUTE_PATH.OFFICE_EXPENSES:
      case ROUTE_PATH.VENDOR_MANAGEMENT:
      case ROUTE_PATH.HISTORY_TRANSACTION:
        setOpenKeys(['sub1'])
        break
      case ROUTE_PATH.EMPLOYEE:
      case ROUTE_PATH.EMPLOYEE_DETAIL:
      case ROUTE_PATH.RESOURCE_SITUATION:
      case ROUTE_PATH.CONTRACT:
      case ROUTE_PATH.CONTRACT_MANAGEMENT:
        setOpenKeys(['sub2'])
        break
      case ROUTE_PATH.CUSTOMER:
      case ROUTE_PATH.PROJECT_CONTRACT:
      case ROUTE_PATH.EVENTS:
        setOpenKeys(['sub3'])
        break
      case ROUTE_PATH.PROJECT_LIST:
      case ROUTE_PATH.STAFFING:
      case ROUTE_PATH.REVENUE_COST:
        setOpenKeys(['sub4'])
        break
      default:
        setOpenKeys([])
        break
    }
  }

  // set selected key
  useEffect(() => {
    const key = rootSubmenuKeys.find(
      (item) => location.pathname.indexOf(item.tabPath) !== -1,
    ).tabKey
    setKeySelected(key)

    updateOpenKeys()
  }, [location.pathname])

  const handleOpenChange = (keys) => {
    setOpenKeys(keys)
  }
  return (
    <SidebarWrapper>
      <div style={{ padding: '26px 46px 26px 46px' }}>
        <img
          src={process.env.PUBLIC_URL + '/images/logo-1bitlab.png'}
          width={'100%'}
          alt=""
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[keySelected]}
        openKeys={openKeys}
        items={items}
        onOpenChange={handleOpenChange}
        onClick={(e) => {
          setKeySelected(e.key)
        }}
      />
    </SidebarWrapper>
  )
}

export default Sidebar
