import http from '.'
import { apiDropDownMenu, apiEnumType } from './apiRouter'

const getEnumType = () => http.get(apiEnumType)
const getDropDownMenu = (string) =>
  http.get(`${apiDropDownMenu}?modelName=${string}`)

const CommonServices = {
  getEnumType,
  getDropDownMenu,
}

export default CommonServices
